import React, { useState, useEffect } from 'react'
import UserSessionContext from '../stores/userSession'

const UserSessionProvider = ({ children }) => {
  const [userSessionState, setUserSessionState] = useState({
    fingerprint: null
  })

  useEffect(() => {
    generateFingerprint()
      .then(fingerprint => {
        setUserSessionState({ fingerprint })
      })
      .catch(err => {
        console.error('Fingerprint generation failed', err)
      })
  }, [])

  return (
    <UserSessionContext.Provider
      value={{
        userSession: userSessionState,
        setUserSession: setUserSessionState
      }}
    >
      {children}
    </UserSessionContext.Provider>
  )
}

async function generateFingerprint() {
  const FingerprintJS = await import('@fingerprintjs/fingerprintjs')
  const fp = await FingerprintJS.load()
  const result = await fp.get()
  return result.visitorId
}

export default UserSessionProvider
