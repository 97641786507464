import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { useStaticQuery, graphql } from 'gatsby'
import { Helmet as ReactHelmet } from 'react-helmet'
import loadjs from 'loadjs'

import isClientSide from '../lib/isClientSide'

function Helmet({ description, lang, pathName, meta, title, titleTemplate }) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            siteUrl
            title
            description
            preconnects
            ogImages {
              en {
                fileName
                height
                width
                alt
              }
              de {
                fileName
                height
                width
                alt
              }
              sq {
                fileName
                height
                width
                alt
              }
            }
            consentData {
              en {
                message
                linkText
                linkUrl
                buttonText
              }
              de {
                message
                linkText
                linkUrl
                buttonText
              }
              sq {
                message
                buttonText
              }
            }
          }
        }
      }
    `
  )

  // Cookieconsent setup
  const consentData = getLocalisedSiteMeta(site.siteMetadata.consentData, lang)
  useEffect(() => {
    if (!isClientSide()) return

    const ccOpts = {
      autoAttach: false,
      theme: 'classic',
      palette: {
        popup: {
          background: '#141414'
        },
        button: {
          background: 'transparent',
          text: '#ffffff',
          border: '#ffffff'
        }
      },
      position: 'bottom-left',
      content: {
        message: consentData.message,
        link: consentData.linkText,
        href: consentData.linkUrl,
        dismiss: consentData.buttonText
      }
    }
    const ccDeps = [
      'https://cdn.jsdelivr.net/npm/cookieconsent@3/build/cookieconsent.min.css',
      'https://cdn.jsdelivr.net/npm/cookieconsent@3/build/cookieconsent.min.js'
    ]
    loadjs(ccDeps, {
      returnPromise: true,
      before(path, element) {
        element.setAttribute('data-cfasync', 'false')
      }
    }).then(() => {
      const cookieconsent = window.cookieconsent
      if (!cookieconsent) return

      cookieconsent.initialise(ccOpts)
      const instance = new cookieconsent.Popup(ccOpts)
      instance.element.id = 'onegate_cc'
      instance.element.classList.add('onegate_cc')
      instance.element.classList.add(`onegate_cc--${lang}`)
      document.body.appendChild(instance.element)
    })

    return () => {
      const ccPopupEl = document.getElementById('onegate_cc')
      if (ccPopupEl) {
        ccPopupEl.remove()
      }
    }
  }, [])

  // Helpcrunch setup
  const HelpCrunch = isClientSide() && window.HelpCrunch
  useEffect(() => {
    if (!HelpCrunch) return

    HelpCrunch('init', 'centralsoft', {
      applicationId: 1,
      applicationSecret:
        'vKKy8bM+28KKYQFF+L2k2XOP6nEuWTaQRVg1MtFtQu8kw0rvR4xMSC7UF/dBsjdbhIGGJDhFCvTqSnr5Qj9dDA=='
    })
    HelpCrunch('showChatWidget')
  }, [HelpCrunch])
  useEffect(() => {
    if (!HelpCrunch) return
    if (!lang) return

    HelpCrunch('setPhraseList', lang)
  }, [lang, HelpCrunch])

  const canonicalUrl = `${site.siteMetadata.siteUrl}/${pathName}/`
  const preconnects = site.siteMetadata.preconnects || []
  const ogImage = getLocalisedSiteMeta(site.siteMetadata.ogImages, lang)
  const metaDescription = description || site.siteMetadata?.description
  const defaultTitle = site.siteMetadata?.title
  const defaultMeta = [
    {
      name: 'description',
      content: metaDescription
    },
    {
      property: 'og:title',
      content: title
    },
    {
      property: 'og:description',
      content: metaDescription
    },
    {
      property: 'og:image',
      content: `${site.siteMetadata.siteUrl}/${ogImage.fileName}`
    },
    {
      property: 'og:image:width',
      content: ogImage.width
    },
    {
      property: 'og:image:height',
      content: ogImage.height
    },
    {
      property: 'og:image:alt',
      content: ogImage.alt
    },
    {
      property: 'og:type',
      content: 'website'
    },
    {
      property: 'og:site_name',
      content: 'Onegate'
    },
    {
      property: 'og:locale',
      content: lang
    },
    {
      property: 'og:url',
      content: canonicalUrl
    },
    {
      name: 'twitter:card',
      content: 'summary_large_image'
    },
    {
      name: 'twitter:title',
      content: title
    },
    {
      name: 'twitter:description',
      content: metaDescription
    },
    {
      name: 'twitter:image:alt',
      content: ogImage.alt
    },
    {
      name: 'twitter:creator',
      content: '@onegateapp'
    },
    {
      name: 'twitter:site',
      content: '@onegateapp'
    }
  ]
  const metaDefinitions = defaultMeta
    .filter(defaultEntry => {
      const prop = defaultEntry.name || defaultEntry.property
      return !meta.some(
        extraEntry => prop === (extraEntry.name || extraEntry.property)
      )
    })
    .concat(meta)

  return (
    <ReactHelmet
      htmlAttributes={{
        lang
      }}
      title={title || defaultTitle}
      titleTemplate={titleTemplate || 'Onegate | %s'}
      meta={metaDefinitions}
      link={[
        {
          rel: 'canonical',
          href: canonicalUrl
        },
        ...preconnects.map(href => ({
          rel: 'preconnect dns-prefetch',
          href
        }))
      ]}
    >
      {/* Helpcrunch */}
      <script
        type="text/javascript"
        innerHTML={`
          (function(w,d){
            w.HelpCrunch=function(){w.HelpCrunch.q.push(arguments)};w.HelpCrunch.q=[];
            function r(){var s=document.createElement('script');s.async=1;s.type='text/javascript';s.src='https://widget.helpcrunch.com/';(d.body||d.head).appendChild(s);}
            if(w.attachEvent){w.attachEvent('onload',r)}else{w.addEventListener('load',r,false)}
          })(window, document);
        `}
      ></script>
    </ReactHelmet>
  )
}

function getLocalisedSiteMeta(localizedMap, lang) {
  const target = localizedMap[lang]
  if (!target) return localizedMap.en
  return target
}

Helmet.defaultProps = {
  lang: 'en',
  meta: [],
  description: ''
}

Helmet.propTypes = {
  lang: PropTypes.string,
  pathName: PropTypes.string.isRequired,
  description: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired
}

export default Helmet
