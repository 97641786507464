import React from 'react'
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { FormattedMessage } from 'gatsby-plugin-intl'

import Subscribe from '../Subscribe/Subscribe'

import styles from './EarlyAcess.module.scss'

const EarlyAcess = () => {
  return (
    <div id="early-access-program" className={`container ${styles.root}`}>
      <div className={styles.section}>
        <h2>
          <FormattedMessage id="earlyAccess.header" />
        </h2>
        <div className={styles.infoContainer}>
          <div className={styles.info}>
            <p>
              <FormattedMessage id="earlyAccess.the" />{' '}
              <span className="focusDarkBg">Onegate</span>{' '}
              <FormattedMessage id="earlyAccess.p1" />
            </p>
            <p>
              <FormattedMessage id="earlyAccess.p2" />
            </p>
            <br />
            <Subscribe dark />
          </div>
          <div className={styles.features}>
            <ul>
              <li className={styles.feature}>
                <div className={styles.icon}>
                  <FontAwesomeIcon icon={faCheckCircle} />
                </div>
                <p>
                  <FormattedMessage id="earlyAccess.features.f1" />
                </p>
              </li>
              <li className={styles.feature}>
                <div className={styles.icon}>
                  <FontAwesomeIcon icon={faCheckCircle} />
                </div>
                <p>
                  <FormattedMessage id="earlyAccess.features.f2" />
                </p>
              </li>
              <li className={styles.feature}>
                <div className={styles.icon}>
                  <FontAwesomeIcon icon={faCheckCircle} />
                </div>
                <p>
                  <FormattedMessage id="earlyAccess.features.f3" />
                </p>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  )
}

export default EarlyAcess
