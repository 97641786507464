import React, { useState } from 'react'
import PropTypes from 'prop-types'

import UserSessionProvider from '../providers/userSession'
import Header from './Header/Header'
import HamburgerMenu from '../components/Header/HamburgerMenu/HamburgerMenu'

import './layout.scss'

const Layout = ({ siteTitle, headerShadow, children }) => {
  const [showMenu, setShowMenu] = useState(false)

  const handleToggleButton = () => {
    setShowMenu(!showMenu)
  }

  return (
    <UserSessionProvider>
      <Header
        siteTitle={siteTitle || 'Onegate'}
        handleToggleButton={handleToggleButton}
        show={showMenu}
        shadow={headerShadow}
      />
      <HamburgerMenu show={showMenu} handleToggleButton={handleToggleButton} />
      <div>
        <main>{children}</main>
      </div>
    </UserSessionProvider>
  )
}

Layout.propTypes = {
  siteTitle: PropTypes.string,
  children: PropTypes.node.isRequired
}

export default Layout
