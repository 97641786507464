import React, { useState } from 'react'
import Button from '../../Button/Button'
import './HamburgerMenu.scss'

const HamburgerMenu = props => {
  let hamburgerMenuClasses = 'hamburgerMenu'
  if (props.show) {
    hamburgerMenuClasses = 'hamburgerMenu open'
  }

  const [open, setOpen] = useState(false)
  const handleOpen = () => {
    setOpen(!open)
  }

  let liClasses = 'submenu'
  if (open) {
    liClasses = 'submenu open'
  }

  return (
    <nav className={hamburgerMenuClasses}>
      <div>
        <ul className="theMenu">
          <li onClick={() => handleOpen()}>
            <div className="submenu-header">
              Features
              <span>
                {' '}
                <div className="arrow"></div>
              </span>
            </div>

            <ul className={liClasses}>
              <li>
                <a href="#branding" onClick={props.handleToggleButton}>
                  Branding
                </a>{' '}
              </li>
              <li>
                {' '}
                <a href="#showcase" onClick={props.handleToggleButton}>
                  Showcase
                </a>{' '}
              </li>
              <li>
                {' '}
                <a href="#booking" onClick={props.handleToggleButton}>
                  Online booking
                </a>{' '}
              </li>
              <li>
                {' '}
                <a href="#fileshare" onClick={props.handleToggleButton}>
                  File sharing
                </a>
              </li>
              <li>
                <a href="#direct" onClick={props.handleToggleButton}>
                  Direct communication
                </a>
              </li>
              <li>
                <a href="#content" onClick={props.handleToggleButton}>
                  Content management
                </a>
              </li>
            </ul>
          </li>
          {/* <li>
            <a onClick={props.handleToggleButton} href="#preview">
              Preview
            </a>
          </li> */}
          <li>
            {' '}
            <a href="#early-access-program" onClick={props.handleToggleButton}>
              Get started
            </a>
          </li>
        </ul>
      </div>
    </nav>
  )
}

export default HamburgerMenu
