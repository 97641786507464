import React from 'react'
import { Link } from 'gatsby'
import { OutboundLink } from 'gatsby-plugin-google-gtag'
import fb from '../../assets/icons/fb.svg'
import instagram from '../../assets/icons/instagram.svg'
import linkedin from '../../assets/icons/linkedin.svg'
import twitter from '../../assets/icons/twitter.svg'

import styles from './Footer.module.scss'
import OnegateBlack from '../../assets/brand/onegate-black.png'
import { FormattedMessage, injectIntl } from 'gatsby-plugin-intl'

const Footer = ({ intl }) => {
  return (
    <div className={styles.root}>
      <div className={`container ${styles.sections}`}>
        <div className={styles.brandSection}>
          <img src={OnegateBlack} alt="onegate logo"></img>
        </div>
        <div className={styles.linksSection}>
          <div className={styles.linksColumn}>
            <p className={styles.columnHeader}>Onegate</p>
            <p>
              <Link to={`/${intl.locale}/#feature-section`}>
                <FormattedMessage id="footer.features" />
              </Link>
            </p>
            {/* <p>
              <Link to={`/${intl.locale}/#preview`}>
                <FormattedMessage id="footer.preview" />
              </Link>
            </p> */}
            <p>
              <Link to={`/${intl.locale}/#early-access-program`}>
                <FormattedMessage id="footer.earlyAccess" />
              </Link>
            </p>
          </div>
          <div className={styles.linksColumn}>
            <p className={styles.columnHeader}>
              <FormattedMessage id="footer.company" />
            </p>
            {/* <p>
              <OutboundLink
                target="_blank"
                rel="noopener"
                href="https://medium.com/onegate"
              >
                <FormattedMessage id="footer.blog" />
              </OutboundLink>
            </p> */}
            <p>
              <Link to={`/${intl.locale}/blog/`}>
                <FormattedMessage id="footer.blog" />
              </Link>
            </p>
            <p>
              <a href="mailto:contact@onegate.app">
                <FormattedMessage id="footer.contact" />
              </a>
            </p>
            {intl.locale !== 'sq' && (
              <p>
                <Link to={`/${intl.locale}/privacy-policy/`}>
                  <FormattedMessage id="footer.privacyPolicy" />
                </Link>
              </p>
            )}
          </div>
        </div>
        <div className={styles.externalSection}>
          <div className={styles.linksColumn}>
            <p className={styles.columnHeader}>
              <FormattedMessage id="footer.followUs" />
            </p>
            <div className={styles.socialMediaContainer}>
              <div className={styles.socialIcon}>
                <OutboundLink
                  target="_blank"
                  rel="noopener"
                  href="https://facebook.com/onegateapp"
                >
                  <img src={fb} alt="facebook logo"></img>
                </OutboundLink>
              </div>
              <div className={styles.socialIcon}>
                <OutboundLink
                  target="_blank"
                  rel="noopener"
                  href="https://instagram.com/onegateapp"
                >
                  <img src={instagram} alt="instagram logo"></img>
                </OutboundLink>
              </div>
              <div className={styles.socialIcon}>
                <OutboundLink
                  target="_blank"
                  rel="noopener"
                  href="https://linkedin.com/company/onegate"
                >
                  <img src={linkedin} alt="linkedin logo"></img>
                </OutboundLink>
              </div>
              <div className={styles.socialIcon}>
                <OutboundLink
                  target="_blank"
                  rel="noopener"
                  href="https://twitter.com/onegateapp"
                >
                  <img src={twitter} alt="twitter logo"></img>
                </OutboundLink>
              </div>
            </div>
            <div className={styles.languagePicker}>
              <p className={styles.label}>
                <span
                  className={`${intl.locale === 'en' ? styles.active : ''}`}
                >
                  <Link to="/en/">English</Link>
                </span>
                <span> | </span>
                <span
                  className={`${intl.locale === 'de' ? styles.active : ''}`}
                >
                  <Link to="/de/"> Deutsch</Link>
                </span>
                <span> | </span>
                <span
                  className={`${intl.locale === 'sq' ? styles.active : ''}`}
                >
                  <Link to="/sq/"> Shqip</Link>
                </span>
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.copyRight}>
        <span>
          <OutboundLink
            target="_blank"
            rel="noopener"
            href="https://centralsoft.io"
          >
            Centralsoft GmbH
          </OutboundLink>{' '}
          &copy; {new Date().getFullYear()}{' '}
          <FormattedMessage id="footer.copyRightExtra" />
        </span>
      </div>
    </div>
  )
}

export default injectIntl(Footer)
