import React from 'react'
import styles from './Button.module.scss'

const Button = ({
  children,
  gradient,
  outline,
  dark,
  handleOnClick,
  menu,
  hamburgerButton,
  ariaLabel,
  black
}) => {
  return (
    <button
      aria-label={ariaLabel}
      onClick={handleOnClick ? event => handleOnClick(event) : () => {}}
      className={`
            ${styles.root} 
            ${gradient ? styles.gradient : ''} 
            ${outline ? styles.outline : ''} 
            ${dark ? styles.dark : ''} 
            ${hamburgerButton ? styles.hamburgerButton : ''}
            ${black ? styles.black : ''}
            // ${menu ? styles.menu : ''}
        `}
    >
      {children}
    </button>
  )
}

export default Button
