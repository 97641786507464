import { createContext } from 'react'

const initialContext = {
  userSession: {
    fingerprint: null
  },
  setUserSession: () => {}
}

const userSessionContext = createContext(initialContext)

export default userSessionContext
