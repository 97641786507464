import React from 'react'
import './HamburgerToggleButton.scss'

const HamburgerToggleButton = props => {
  let toggleMenuClasses = 'toggleButton'
  if (props.show) {
    toggleMenuClasses = 'toggleButton animate'
  }
  return (
    <button
      className={toggleMenuClasses}
      onClick={props.click}
      aria-label="Toggle"
    >
      <React.Fragment>
        <div className="line"></div>
        <div className="line"></div>
        <div className="line"></div>
      </React.Fragment>
    </button>
  )
}

export default HamburgerToggleButton
