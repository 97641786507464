import isClientSide from './isClientSide'

export function getVisibleColor(hexcolor) {
  if (hexcolor === '#fff' || hexcolor === '#ffffff') return 'dark'
  if (hexcolor === '#000' || hexcolor === '#000000') return 'light'

  hexcolor = hexcolor.substr(1)
  var r = parseInt(hexcolor.substr(0, 2), 16)
  var g = parseInt(hexcolor.substr(2, 2), 16)
  var b = parseInt(hexcolor.substr(4, 2), 16)
  var yiq = (r * 299 + g * 587 + b * 114) / 1000
  return yiq >= 128 ? 'dark' : 'light'
}

export function fireGtagEvent({ action, category, label, value }) {
  if (!isClientSide() || !window.gtag) return

  const payload = {
    event_category: category,
    event_label: label
  }
  if (value != null) {
    payload.value = value
  }
  window.gtag('event', action, payload)
}

export function fireFbEvent({ action, params }) {
  if (!isClientSide() || !window.fbq) return

  window.fbq('track', action, params)
}
