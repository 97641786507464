import { Link } from 'gatsby'
import PropTypes from 'prop-types'
import React from 'react'
import styles from './Header.module.scss'
import Button from '../Button/Button'
import OnegateLogo from '../../assets/brand/onegate-logo-website.png'
import HamburgerToggleButton from './HamburgerMenu/HamburgerToggleButton'
import { FormattedMessage, injectIntl } from 'gatsby-plugin-intl'

const Header = ({ siteTitle, show, handleToggleButton, shadow, intl }) => {
  return (
    <header className={`${styles.header} ${shadow ? styles.shadow : ''}`}>
      <div className={`container ${styles.navbar}`}>
        <div className={styles.logo}>
          <img src={OnegateLogo} alt="onegate logo"></img>
          <Link to="/">{siteTitle}</Link>
        </div>
        <nav className="sidebar">
          <ul>
            <li>
              <Link className={styles.features} to="/">
                <FormattedMessage id="features.navHeader" />
              </Link>
              <ul className={styles.submenu}>
                <li>
                  <Link to={`/${intl.locale}/#branding`}>
                    {' '}
                    <FormattedMessage id="features.branding.title" />
                  </Link>{' '}
                </li>
                <li>
                  {' '}
                  <Link to={`/${intl.locale}/#showcase`}>
                    {' '}
                    <FormattedMessage id="features.showcase.title" />
                  </Link>{' '}
                </li>
                <li>
                  {' '}
                  <Link to={`/${intl.locale}/#booking`}>
                    {' '}
                    <FormattedMessage id="features.booking.title" />
                  </Link>{' '}
                </li>
                <li>
                  {' '}
                  <Link to={`/${intl.locale}/#fileshare`}>
                    {' '}
                    <FormattedMessage id="features.fileSharing.title" />
                  </Link>
                </li>
                <li>
                  <Link to={`/${intl.locale}/#direct`}>
                    {' '}
                    <FormattedMessage id="features.directCommunication.title" />
                  </Link>
                </li>
                <li>
                  <Link to={`/${intl.locale}/#content`}>
                    {' '}
                    <FormattedMessage id="features.contentManagement.title" />
                  </Link>
                </li>
              </ul>
            </li>
            {/* <li>
              <Link to={`/${intl.locale}/#preview`}>
                <FormattedMessage id="preview.header" />
              </Link>
            </li> */}
            <li>
              <Link to={`/${intl.locale}/blog/`}>
                <FormattedMessage id="header.blog" />
              </Link>
            </li>
            <li>
              <Link to={`/${intl.locale}/#frequently-asked-questions`}>
                <FormattedMessage id="header.faq" />
              </Link>
            </li>
            <li>
              {' '}
              <Link to={`/${intl.locale}/#early-access-program`}>
                <Button
                  outline
                  ariaLabel={intl.formatMessage({ id: 'header.getStarted' })}
                >
                  {' '}
                  <FormattedMessage id="header.getStarted" />{' '}
                </Button>
              </Link>
            </li>
          </ul>
        </nav>
        <div className={styles.burger}>
          <HamburgerToggleButton
            click={handleToggleButton}
            show={show}
          ></HamburgerToggleButton>
        </div>
      </div>
    </header>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string
}

Header.defaultProps = {
  siteTitle: ``
}

export default injectIntl(Header)
