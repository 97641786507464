/* eslint-disable react/display-name */
import React, { useState } from 'react'
import { Link } from 'gatsby'
import { FormattedMessage, injectIntl } from 'gatsby-plugin-intl'

import useUserSession from '../../hooks/userSession'
import { fireGtagEvent, fireFbEvent } from '../../lib/utils'

import styles from './Subscribe.module.scss'

const Subscribe = ({ dark, intl }) => {
  const { userSession } = useUserSession()

  const [isDirty, setIsDirty] = useState(false)
  const [isSubmitted, setIsSubmitted] = useState(false)
  // const [name, setName] = useState('')
  // const [phone, setPhone] = useState('')
  const [email, setEmail] = useState('')

  const isValid = validateEmail(email)
  return (
    <div className={styles.root}>
      <form
        className={`${styles.wrapper} ${dark ? styles.dark : ''}`}
        onSubmit={e => {
          e.preventDefault()
          if (!isValid || isSubmitted) return

          updateUser({ email, userId: userSession.fingerprint })
          showWelcomeMessage({ locale: intl.locale })
          setIsSubmitted(true)
          fireGtagEvent({
            action: 'subscription_early_access',
            category: 'subscription',
            label: `New subscription in ${dark ? 'footer' : 'intro'}`
          })
          fireFbEvent({
            action: 'subscription_early_access',
            params: {
              label: `New subscription in ${dark ? 'footer' : 'intro'}`
            }
          })
        }}
      >
        {/* Enable the following inputs later */}
        {/* <input
          className={styles.optionalInput}
          type="text"
          name="name"
          placeholder={intl.formatMessage({ id: "subscribe.yourName" })}
          value={name}
          onChange={(e) => {
            setIsDirty(true)
            setIsSubmitted(false)
            setName(e.target.value)
          }}
        />
        {extended &&
          <input
            className={styles.optionalInput}
            type="tel"
            name="phone"
            placeholder={intl.formatMessage({ id: "subscribe.yourPhone" })}
            value={phone}
            onChange={(e) => {
              setIsDirty(true)
              setIsSubmitted(false)
              setPhone(e.target.value)
            }}
          />
        } */}
        <div
          id={`label-test-${dark ? 'early' : 'intro'}`}
          className={`${styles.submitWrapper} ${
            isSubmitted ? styles.submitted : ''
          }`}
        >
          <input
            aria-labelledby={`label-test-${dark ? 'early' : 'intro'}`}
            required
            type="email"
            name="email"
            placeholder={intl.formatMessage({ id: 'subscribe.yourEmail' })}
            value={email}
            onChange={e => {
              setIsDirty(true)
              setIsSubmitted(false)
              setEmail(e.target.value)
            }}
          />
          <button
            disabled={isDirty && !isValid}
            type="submit"
            aria-label={intl.formatMessage({
              id: 'subscribe.join'
            })}
          >
            {!isSubmitted && <FormattedMessage id="subscribe.join" />}
            {isSubmitted && <span>🎉</span>}
          </button>
        </div>
        {intl.locale !== 'sq' && (
          <div className={styles.legalTextWrapper}>
            <p className={styles.legalText}>
              <FormattedMessage
                id="subscribe.legalText"
                values={{
                  privacylink: chunks => (
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href={`/${intl.locale}/privacy-policy/`}
                    >
                      {chunks}
                    </a>
                  )
                }}
              />
            </p>
          </div>
        )}
      </form>
    </div>
  )
}

function updateUser({ userId, email }) {
  const user = {
    email,
    user_id: userId,
    custom_data: {
      onegate: true,
      early_access_program: true
    }
  }
  if (!userId) {
    delete user.user_id
  }
  HelpCrunch('updateUser', user)
}

function showWelcomeMessage({ locale }) {
  if (locale === 'de') {
    HelpCrunch('sendProactiveChatAutoMessage', 11)
  } else {
    HelpCrunch('sendProactiveChatAutoMessage', 10)
  }
}

function validateEmail(email) {
  const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return re.test(String(email).toLowerCase())
}

export default injectIntl(Subscribe)
